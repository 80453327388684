import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Formik } from "formik";
import JInputText from "@/base/JInputText";
import { OrganizationSettingKey, OrganizationSettingsRecord } from "@/types";
import { useUserContext } from "../Users/UserContext";
import Loader from "@/base/Loader";
import { useEffect, useState } from "react";
import { batchOrgSettingsUpdate, getNotificationsTimeInitialValues, setNotificationsTimeValues } from "./utils";
import SlackIntegrationButton from "@/components/SlackIntegrationButton";
import SlackApiChangeModalConfirm from "./SlackApiChangeModalConfirm";
import { InputSwitch } from "primereact/inputswitch";
import { useNotificationsContext } from "../Notifications/NotificationContext";
import SettingsSuperAdmin from "./SettingsSuperAdmin";
import { useTimezoneSelect } from "react-timezone-select";
import JDropdown from "@/base/JDropdown/JDropdown";
import { timezones } from "../../constants";
import { Tooltip } from "primereact/tooltip";

type SettingsPatchProps = OrganizationSettingsRecord & {
  schedule_notification_update: string;
  schedule_notification_from: string;
  schedule_notification_to: string;
};

export const Settings = () => {
  const [showSlackApiChangeModal, setShowSlackApiChangeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, saveOrgSettings, getUserData, isChannelManager, orgSettings } = useUserContext();
  const { status, getNotificationStatus, setNotificationStatus } = useNotificationsContext();
  const {
    schedule_notification_from_hour: orgFromHour,
    schedule_notification_from_minute: orgFromMin,
    schedule_notification_to_hour: orgToHour,
    schedule_notification_to_minute: orgToMin,
    schedule_notification_update_hour: orgUpdateHour,
    schedule_notification_update_minute: orgUpdateMin,
  } = orgSettings;

  const isSuperAdmin = user?.type === "SUADMIN";

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({ labelStyle: "original", timezones });

  useEffect(() => {
    getNotificationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapFormValuesToOrgSettings = (formValues: SettingsPatchProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { schedule_notification_from, schedule_notification_to, schedule_notification_update, ...restValues } =
      formValues;

    const notificationsValues = setNotificationsTimeValues({
      schedule_notification_from,
      schedule_notification_to,
      // schedule_notification_update,
    });

    const values: Record<OrganizationSettingKey, string> = {
      ...restValues,
      ...(notificationsValues as Record<OrganizationSettingKey, string>),
    };
    return values;
  };

  const handleSaveChanges = async (formValues: SettingsPatchProps) => {
    const values = mapFormValuesToOrgSettings(formValues);
    await batchOrgSettingsUpdate({
      getUserData,
      setIsLoading,
      saveOrgSettings,
      values,
      orgSettings,
      showSuccessToast: true,
    });
  };

  const toggleGlobalNotifications = async ({ value }: { value: boolean }) => {
    await setNotificationStatus(value);
    await getUserData();
  };

  if (isSuperAdmin) {
    return <SettingsSuperAdmin />;
  }

  return (
    <div>
      <Loader isLoading={isLoading} />
      <div className="mb-4 flex items-center justify-center">
        <span className="text-[40px]">Settings</span>
      </div>
      <Formik<SettingsPatchProps>
        initialValues={{
          ...orgSettings,
          ...getNotificationsTimeInitialValues({
            orgFromHour,
            orgFromMin,
            orgToHour,
            orgToMin,
            orgUpdateHour,
            orgUpdateMin,
          }),
          timezone: parseTimezone(orgSettings.timezone).value,
        }}
        onSubmit={handleSaveChanges}
        enableReinitialize
      >
        {({ submitForm, dirty, values, setFieldValue, resetForm }) => (
          <div className="flex flex-col gap-4">
            <div className="mx-auto flex w-[900px] flex-col gap-4">
              <div className="flex w-full justify-between gap-2">
                <Fieldset legend="Org. manager details" className="w-full">
                  <div className="flex flex-col gap-2">
                    <div>
                      {user?.firstName} {user?.lastName}
                    </div>
                    <div>{user?.email}</div>
                    <div>{user?.type === "ADMIN" ? "Org. Manager" : "Channel Manager"}</div>
                  </div>
                </Fieldset>
                <Fieldset legend="Organization details" className="w-full">
                  <div>{user?.organization?.title}</div>
                </Fieldset>
              </div>
              {!isChannelManager && (
                <Fieldset legend="Slack settings">
                  <div className="mb-4 flex flex-col gap-2">
                    <JInputText
                      autoComplete="off"
                      type="text"
                      obfuscateIfNoValue={user?.isSlackAvailable}
                      id="slack_api_key"
                      title="Slack API token"
                      placeholder="Slack API token"
                      className="w-full"
                    />
                    <div className="flex items-center gap-6">
                      <SlackIntegrationButton />
                      <span
                        className="w-max min-w-[150px] cursor-pointer underline"
                        onClick={() => window.open("https://api.slack.com/apps", "_blank")}
                      >
                        Get token instructions
                      </span>
                      <JInputText
                        id="slack_workspace_id"
                        title="Slack Team ID"
                        placeholder="Slack Team or Workspace ID"
                        className="w-full"
                      />
                    </div>
                  </div>
                </Fieldset>
              )}
              <Fieldset legend="Notifications">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-8">
                    <div className="flex gap-4">
                      <span className="font-bold">Notifications status</span>
                      <InputSwitch
                        tooltip="Notification status"
                        tooltipOptions={{ position: "left" }}
                        checked={status}
                        onChange={toggleGlobalNotifications}
                      />
                    </div>
                    <div className="flex gap-4">
                      <JDropdown
                        id="timezone"
                        options={timezoneOptions}
                        title="Organization timezone"
                        optionLabel="label"
                        optionValue="value"
                        showClear={false}
                        className="w-full min-w-[200px]"
                        onChange={(e) => {
                          const value = parseTimezone(e.value).value;
                          setFieldValue("timezone", value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-8">
                    <JInputText
                      disabled={isChannelManager}
                      id="schedule_notification_from"
                      title="Show from"
                      className=""
                      type="time"
                    />
                    <JInputText
                      disabled={isChannelManager}
                      id="schedule_notification_to"
                      title="Show to"
                      className=""
                      type="time"
                    />
                    {/* <JInputText
                      disabled={isChannelManager}
                      id="schedule_notification_update"
                      title="Update at"
                      className=""
                      type="time"
                    /> */}
                  </div>
                </div>
              </Fieldset>
              {!isChannelManager && (
                <Fieldset legend="BambooHR settings">
                  <JInputText
                    autoComplete="off"
                    type="text"
                    obfuscateIfNoValue={user?.isBambooAvailable}
                    id="bamboo_api_key"
                    title="Bamboo API token"
                    placeholder="Bamboo API token"
                    className="w-full"
                  />
                  <JInputText
                    id="bamboo_workspace_id"
                    title="Workspace Title"
                    placeholder="MyCompany"
                    className="w-full"
                  />
                  <div className="flex gap-8">
                    <JInputText
                      disabled={isChannelManager}
                      placeholder="<role>"
                      id="bamboo_role_field"
                      title="Role field title"
                    />
                    <JInputText
                      disabled={isChannelManager}
                      placeholder="<project>"
                      id="bamboo_project_field"
                      title="Project field title"
                    />
                  </div>
                </Fieldset>
              )}
            </div>
            {!isChannelManager && (
              <div className="mx-auto flex gap-4">
                {!dirty && <Tooltip target="#submitButtonWrapper" content="No changes to save" position="top" />}
                <div id="submitButtonWrapper">
                  <Button
                    disabled={!dirty}
                    label="Save changes"
                    onClick={async () => {
                      if (values.slack_api_key) {
                        setShowSlackApiChangeModal(true);
                        return;
                      }

                      await submitForm();
                      await resetForm();
                    }}
                  />
                </div>
              </div>
            )}
            <SlackApiChangeModalConfirm
              visible={showSlackApiChangeModal}
              onCancel={() => setShowSlackApiChangeModal(false)}
              onConfirm={async () => {
                try {
                  await submitForm();
                  await resetForm();
                  setShowSlackApiChangeModal(false);
                } catch (e) {
                  console.error(e);
                }
              }}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Settings;
